import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { VacanciesModule } from '../vacancies.module';
import { Subject, Observable } from 'rxjs';
import { CandidatesFilter } from '../../core/models/candidates-filter';
import { VacancyCandidatesService } from '../vacancy-candidates.service';
import { CleanObjectService } from '../../core/services';
import { VacanciesService } from '../vacancies.service';
import { take } from 'rxjs/operators';
import { Step, Vacancy } from '../../core/models';
import { IVacancyCandidatesResponse } from '../../core/interfaces/http-responses';
import { isEqual, groupBy, remove, isNull, get } from 'lodash';
import * as moment from 'moment';
moment.locale('pt-br');

@Injectable()
export class CandidateListService {

  selectCandidate = new Subject<boolean>();
  private filters: Partial<CandidatesFilter> = {};
  private filtersChanged = new Subject<any>();
  private stepsChanged = new Subject<Step[]>();
  private paginationChanged = new Subject<any>();
  private candidatesList = new Subject<any>();
  public goToTop = new Subject<void>();
  private vacancyId: string;
  private pagination = {
    skip: 0,
    limit: 50
  };
  private nextPaginationSkip = this.pagination.limit;
  private limitedModeActive: boolean = false;

  constructor(
    private vacancyCandidatesService: VacancyCandidatesService,
    private vacanciesService: VacanciesService,
    private cleanObjectService: CleanObjectService,
    private router: Router
  ) { }

  mountFilters(filters: Object): void {
    const filterTemplate = new CandidatesFilter();

    for (const [key, value] of Object.entries(filters)) {
      if (this.isArray(filterTemplate[key])) {
        if (!this.filters[key]) {
          this.filters[key] = [];
        }

        switch (key) {
          case 'softwares':
          case 'languages':
            const skillIndex = value ? this.filters[key]
              .findIndex(skill => !!value.find(item => item.name === skill.name)) : null;

            if (skillIndex && skillIndex < 0) {
              this.filters[key].push(...value);
            } else {
              this.filters[key][skillIndex] = value[0];
            }
          break;
          case 'companyCertifications':
            this.filters[key] = value;
          break;
          default:
            if (this.isArray(value)) {
              let index;
              if (key === 'schooling' || key === 'professionalExperience' || key === 'geolocations') {
                index = value ? this.filters[key]
                  .findIndex(skill => !!value.find(item => isEqual(item, skill))) : null;
              }
  
              if (index) {
                if (index < 0) {
                  this.filters[key].push(...(<Array<any>>value));
                } else {
                  this.filters[key][index] = value[0];
                }
              }
            } else {
              this.filters[key].push(value);
            }
          break;
        }

        // if (key === 'softwares' || key === 'languages') {
        //   const skillIndex = value ? this.filters[key]
        //     .findIndex(skill => !!value.find(item => item.name === skill.name)) : null;

        //   if (skillIndex && skillIndex < 0) {
        //     this.filters[key].push(...value);
        //   } else {
        //     this.filters[key][skillIndex] = value[0];
        //   }
        // } else {
        //   if (this.isArray(value)) {
        //     let index;
        //     if (key === 'schooling' || key === 'professionalExperience' || key === 'geolocations' || key === 'companyCertifications') {
        //       index = value ? this.filters[key]
        //         .findIndex(skill => !!value.find(item => isEqual(item, skill))) : null;
        //     }

        //     if (index) {
        //       if (index < 0) {
        //         this.filters[key].push(...(<Array<any>>value));
        //       } else {
        //         this.filters[key][index] = value[0];
        //       }
        //     }
        //   } else {
        //     this.filters[key].push(value);
        //   }
        // }

      } else {
        if (this.isObject(filterTemplate[key])) {
          if (key === 'managersActions') {
            if (this.checkIsNull(value)) return;
          }

          if (key === 'supplementaryQuestions') {
            this.filters[key] = {
              ...value

            };
          }

          this.filters[key] = {
            ...this.filters[key],
            ...value
          };
        } else {
          this.filters[key] = value;
        }
      }
    }
    this.filters = this.cleanObjectService.getCleanedAttribute(this.filters);
    // this.filters = filters
  }

  isArray(obj) {
    return !!Object.prototype.toString.call(obj).match(/Array/);
  }

  isObject(obj) {
    return !!Object.prototype.toString.call(obj).match(/Object/);
  }

  deepKeys(t, path = []): any {
    return Object(t) === t
      ? Object
        .entries(t)
        .flatMap(([k, v]) => this.deepKeys(v, [...path, k]))
      : [path.join(".")];
  }

  checkIsNull(obj): boolean {
    const keys = [...Object.keys(obj), ...this.deepKeys(obj)];
    const isNullCheck = keys.every(key => {
      if (this.isObject(obj[key])) {
        return this.checkIsNull(obj[key]);
      }
      return isNull(get(obj, key));
    });
    return isNullCheck;
  }

  fetchCandidates(rootType: string = 'vacancies'): Observable<IVacancyCandidatesResponse> {
    const params = {
      vacancyId: this.vacancyId,
      filters: this.cleanObjectService.getCleanedAttribute({
        ...(!this.filters.sort ? { sort: { type: 'affinity', order: '-1' } } : {}),
        ...(!this.filters.step ? { step: '5a1109341f8773001' } : {}),
        ...this.filters,
      }),
      pagination: this.pagination,
      limitedModeActive: this.limitedModeActive,
    };

    if (Object.keys(params.filters).length === 0) {
      this.router.navigate([`${rootType}/${this.vacancyId}/candidates-list`]);
    } else {
      this.router.navigate([`${rootType}/${this.vacancyId}/candidates-list`], {
        queryParams: {
          'filter': JSON.stringify(params.filters)
        },
        replaceUrl: true
      });
    }
    return this.vacancyCandidatesService.getVacancyCandidatesNewList(params, this.vacancyId);
  }

  setFilters(filters: Object): void {
    this.resetPagination();

    this.mountFilters(filters);
    this.candidatesList.next();
    this.filtersChanged.next(this.filters);
  }

  getFilters() {
    return this.filtersChanged;
  }

  getCandidates() {
    return this.candidatesList;
  }

  getSelectCandidateObservable(): Observable<any> {
    return this.selectCandidate;
  }

  setSteps(steps: Array<Step>) {
    this.stepsChanged.next(steps);
  }

  getStepsChanges() {
    return this.stepsChanged;
  }

  resetPagination() {
    this.pagination = {
      skip: 0,
      limit: this.limitedModeActive ? 20 : 50
    };

    this.nextPaginationSkip = this.pagination.limit;
  }

  setPagination(pagination) {
    this.setNextPaginationSkip(pagination);

    this.paginationChanged.next({
      pagination,
      nextPaginationSkip: this.nextPaginationSkip
    });
  }

  getPaginationChanges() {
    return this.paginationChanged;
  }

  resetFilters() {
    this.filters = {};
  }

  setNextPaginationSkip({ total, limit }): void {
    if (total < limit || this.nextPaginationSkip > total) {
      this.nextPaginationSkip = total;
    }
  }

  goNextPage() {
    this.pagination.skip += this.pagination.limit;
    this.nextPaginationSkip += this.pagination.limit;
    this.candidatesList.next();
    this.goToTop.next();
  }

  goPreviousPage() {
    this.pagination.skip -= this.pagination.limit;
    if (this.pagination.skip) {
      this.nextPaginationSkip -= this.pagination.skip;
    } else {
      this.nextPaginationSkip = this.limitedModeActive ? 20 : 50;
    }
    this.candidatesList.next();
    this.goToTop.next();
  }

  updateCandidatesList(condition: boolean): void {
    if (condition) {
      this.setFilters({});
      this.updateStepsCandidatesCount();
    }
  }

  updateStepsCandidatesCount(): void {
    this.vacanciesService.getVacancyProcess(this.vacancyId)
      .pipe(take(1))
      .subscribe(process => this.setSteps(process.steps));
  }

  setVacancyId(id: string) {
    this.vacancyId = id;
  }

  removeFilters(filter?: Array<string>): void {
    const filters = filter || Object.keys(this.filters);
    filters.forEach(name => delete this.filters[name]);
    // Gambiarra para não deixar remover o filtro de 'Condição do candidato'
    if (!this.filters.state) this.filters.state = `inProcess`;
    this.candidatesList.next();
    this.filtersChanged.next(this.filters);
  }

  setVacancyProcessSteps(vacancy: Vacancy) {
    const states = ['inProcess', 'notInProcess'];
    vacancy.process.steps.forEach((step, stepIndex) => {
      states.forEach(state => {
        vacancy.process.steps[stepIndex].status = vacancy.process.steps[stepIndex].status || {};
        vacancy.process.steps[stepIndex].status[state] = vacancy.process.status[state].filter(status => {

          let availableForThisStep = status.availableForAllSteps;
          if (!availableForThisStep) {
            if (status.stepKeys && status.stepKeys.length) {
              availableForThisStep = status.stepKeys.indexOf(vacancy.process.steps[stepIndex].key) !== -1;
            } else {
              availableForThisStep = status.stepIndexes.indexOf(stepIndex) !== -1;
            }
          }

          if (!availableForThisStep) {
            return false;
          }

          if (!status.rule) {
            return true;
          }
        });

        this.groupedSchedules(vacancy, stepIndex);
      });
    });
  }

  groupedSchedules(vacancy: Vacancy, stepIndex: number) {
    if (vacancy.process.steps[stepIndex].schedules && vacancy.process.steps[stepIndex].schedules.length) {
      remove(vacancy.process.steps[stepIndex].schedules, schedule => schedule.active === false);
      vacancy.process.steps[stepIndex].schedules.forEach(schedule => {
        schedule.date = schedule.date ? new Date(schedule.date) : new Date(schedule.start);
        schedule.isToday = moment().isSame(schedule.date, 'day');
        schedule.isPastDate = schedule.date < new Date() ? true : false;

        // this.scheduleHelper[schedule._id] = schedule;
      });

      vacancy.process.steps[stepIndex].schedules.sort((scheduleA, scheduleB) =>
        +scheduleA.date - +scheduleB.date
      );

      const today = new Date().setHours(0, 0, 0, 0);
      const groupedSchedulesObject = groupBy(vacancy.process.steps[stepIndex].schedules, schedule => {
        if (schedule.date.getTime() < today) {
          return 'Datas passadas';
        }

        return String(schedule.date.getDate()).padStart(2, '0') + '/' + String(schedule.date.getMonth() + 1).padStart(2, '0');
      });

      vacancy.process.steps[stepIndex].groupedSchedules = [];
      Object.keys(groupedSchedulesObject).forEach(key => {
        vacancy.process.steps[stepIndex].groupedSchedules.push({
          name: key,
          pastDates: (key === 'Datas passadas' ? true : false),
          schedules: groupedSchedulesObject[key],
          isCollapsed: true
        });
      });

      vacancy.process.steps[stepIndex].groupedSchedules.push(vacancy.process.steps[stepIndex].groupedSchedules[0]);
      vacancy.process.steps[stepIndex].groupedSchedules.splice(0, 1);
    }
  }

  setLimitedMode(limitedModeActive: boolean) {
    this.limitedModeActive = limitedModeActive;
    localStorage.setItem('limitedModeActive', JSON.stringify(limitedModeActive));

    this.resetPagination();
    this.paginationChanged.next({
      pagination: this.pagination,
      nextPaginationSkip: this.nextPaginationSkip
    });

    this.candidatesList.next();
  }
}
