import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { ToastrService } from 'ngx-toastr';
import { scheduled, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Step } from '../../../core/models';
import { CleanObjectService, StorageService } from '../../../core/services';
import { VacanciesService } from '../../vacancies.service';
import { ConfirmModalComponent } from './../confirm-modal/confirm-modal.component';
import * as moment from 'moment';

export function spaceValidator(control: FormControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
    return { required: true }
  } else {
    return null;
  }
}

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit, OnDestroy {
  @ViewChild('searchLocation') public searchElement: ElementRef;
  @Input() steps;
  @Input() vacancyName;
  @Input() vacancyId;
  @Input() eventObj;
  @Input() vacancyProcess;

  public showInputStep: boolean = false;

  form: FormGroup;
  currentDate = new Date();
  maxDate = new Date(new Date().setDate(new Date().getDate() + 15));
  latitude: number = null;
  longitude: number = null;
  blockStep: boolean;
  selectedStep: string;
  zoom: number;
  locationInformationByGoogle: google.maps.places.PlaceResult;
  locationImgPreviewUrl: string;
  submitted: boolean;
  listenChanges: Subscription;
  dateObject: any = {};
  zone: any = {};

  private isCreatingOrUpdating: boolean = false;
  public isTaqeLite: boolean = environment.talent_base as boolean;
  public APP_WHITELABEL_NAME: string = environment.manager_type.toUpperCase();
  public WEB_LINK: string = environment.web_link;
  // Etapa: Armazena a etapa de agendamento da vitrine.
  public showcaseSchedulingStep: Step;
  public isFormSubmited: boolean = false;

  userManager: any;
  company: any;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private vacanciesService: VacanciesService,
    private clearService: CleanObjectService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.blockStep = false;

    // Etapa: Armazena as informações do usuário logado.
    this.userManager = this.storage.getUser();
    this.company = this.storage.getCompany();

    const baseFields: any = {
      _id: [null, Validators.nullValidator],
      title: [null, [Validators.required, spaceValidator]],
      location: this.formBuilder.group({
        name: ['', Validators.nullValidator],
        address: this.formBuilder.group({
          formatted_address: [null, Validators.nullValidator],
          geometry: this.formBuilder.group({
            lat: [null, Validators.nullValidator],
            lng: [null, Validators.nullValidator],
          }),
        }),
      }),
      scheduleBaseId: [null, Validators.nullValidator],
      stepId: [null, Validators.required],
      manager: [null, Validators.nullValidator],
      managerEmail: [null, [Validators.compose([Validators.email]), spaceValidator]],
      responsible: [null, [Validators.required, spaceValidator]],
      responsibleEmail: [null, [Validators.compose([Validators.required, Validators.email]), spaceValidator]],
      maxCandidatesCount: [1, Validators.nullValidator],
      date: [null, Validators.required],
      start: [null, Validators.required],
      end: [null, Validators.required],
      interviewType: ['individual', Validators.required],
      linkInvite: [null, [Validators.required, Validators.pattern(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)]],
      type: ['online', Validators.required],
      description: [null, Validators.nullValidator]
    };

    const fullForm = this.isTaqeLite ?
    {
      ...baseFields,
      ...{
        company: [this.userManager.company, Validators.required]
      }
    } : baseFields;

    // Etapa: Cria o formulário.
    this.form = this.formBuilder.group(fullForm);

    if (environment.talent_base) {
      this.showcaseSchedulingStep = this.steps.find((step: any) => String(step.key) === 'scheduling' && String(step.flow) === 'showcase');

      if (this.showcaseSchedulingStep && this.showcaseSchedulingStep._id) {
        this.form.get('stepId').setValue(this.showcaseSchedulingStep._id);
      } else {
        this.showInputStep = true;
      }

      // Etapa: Retorna a lista e agendamentos ativos e cadastrado pela empresa.
      if (this.showcaseSchedulingStep.schedules && this.showcaseSchedulingStep.schedules.length) {
        this.showcaseSchedulingStep.schedules = this.showcaseSchedulingStep.schedules
          .filter((schedule: any) => String(schedule.active) && String(schedule.company) === String(this.userManager.company));
      }
    } else {
      this.showInputStep = true;
    }

    if (this.eventObj) {
      this.fillForm();
      this.zoom = 12;
      this.blockStep = true;
    } else {
      // this.changeRequiredStatus(this.isTaqeLite ? 'online' : 'presencial');
    }

    this.onChanges();
  }

  loadMap(): void {
    if (!this.searchElement || !this.searchElement.nativeElement) {
      return;
    }

    this.mapsAPILoader.load()
      .then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
          componentRestrictions: {
            country: 'br'
          }
        });

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            this.locationInformationByGoogle = autocomplete.getPlace();
            this.locationImgPreviewUrl = 'https://maps.googleapis.com/maps/api/staticmap?zoom=12&size=600x300&markers=color:red|' +
              this.locationInformationByGoogle.formatted_address + '&key=' + environment.MAPS_API_KEY;
            this.form.get('location.address.formatted_address').patchValue(autocomplete.getPlace().formatted_address);

            this.latitude = autocomplete.getPlace().geometry.location.lat();
            this.longitude = autocomplete.getPlace().geometry.location.lng();
            this.zoom = 12;

            if (!autocomplete.getPlace().geometry) {
              this.toastrService.clear();
              this.toastrService.warning('Este endereço não possui uma geolocalização bem definida no Google Maps, o que pode confundir o candidato no dia do agendamento.', 'Atenção');
            }

            this.form.get('location.address.geometry.lat').patchValue(this.latitude);
            this.form.get('location.address.geometry.lng').patchValue(this.longitude);
          });
        });
      });
  }

  close(): void {
    this.activeModal.close();
  }

  fillForm(): void {
    this.form.patchValue(this.eventObj.extendedProps);
    this.changeRequiredStatus(this.form.get('type').value);

    this.form.get('title').patchValue(this.eventObj.title);
    if (this.form.get('type').value === 'presencial') {
      this.latitude = this.form.get('location.address.geometry.lat').value ? this.form.get('location.address.geometry.lat').value : this.eventObj.extendedProps.location.address.geometry.lat;
      this.longitude = this.form.get('location.address.geometry.lng').value ? this.form.get('location.address.geometry.lng').value : this.eventObj.extendedProps.location.address.geometry.lng;
    }

    this.form.get('start').patchValue({
      hour: new Date(this.eventObj.start).getHours(),
      minute: new Date(this.eventObj.start).getMinutes()
    });

    this.form.get('end').patchValue({
      hour: new Date(this.eventObj.end).getHours(),
      minute: new Date(this.eventObj.end).getMinutes()
    });

    this.form.get('date').patchValue(this.eventObj.start);

    this.dateObject = {
      year: new Date(this.eventObj.start).getUTCFullYear(),
      month: new Date(this.eventObj.start).getUTCMonth() + 1,
      day: new Date(this.eventObj.start).getUTCDate()
    };

    this.dateChange({ value: this.eventObj.start });
  }

  async addEvent() {
    if (!this.form.valid) {
      // this.submitted = true;
      this.toastrService.clear();
      this.toastrService.error('Erro no formulário!', 'Preencha os campos obrigatórios!');
      return;
    }



    if (this.form.get('interviewType').value == 'individual') {
      this.form.get('maxCandidatesCount').patchValue(1);
    }

    let scheduleForm = this.form.value;
    scheduleForm.date = this.dateObject;

    this.isFormSubmited = true;
    const warningResolutions = [];

    if(await this.validateExistingEvent()) {
      const result = await this.openExistingModal();
      warningResolutions.push(result);
    }
    
    if(!this.verifyIfEventDateIsFeasible()) {
      const result = await this.open48hoursWarningModal();
      warningResolutions.push(result);
    }

    if(warningResolutions.every((result) => result)) this.createOrUpdateEvent();
  }

  private createOrUpdateEvent(): void {
    if (this.isCreatingOrUpdating) {
      return;
    }

    this.isCreatingOrUpdating = true;

    let scheduleForm = this.form.value;
    scheduleForm.date = this.dateObject;

    if (!this.eventObj || this.eventObj.newEvent) {
      this.vacanciesService.createSchedule(this.vacancyId || this.eventObj.extendedProps.vacancyId, this.form.value.stepId, { schedule: this.clearService.getCleanedAttribute(scheduleForm) })
        .subscribe(response => {
          this.toastrService.clear();
          this.toastrService.success('O agendamento foi cadastrado com sucesso!', 'Cadastro realizado!');
          this.activeModal.close({
            ...response,
            ... {
              vacancyId: this.vacancyId || this.eventObj.extendedProps.vacancyId,
              stepId: this.form.value.stepId
            }
          });
        });
    } else {
      this.vacanciesService.updateSchedule(this.eventObj.extendedProps.vacancyId, this.form.value.stepId, { schedule: this.clearService.getCleanedAttribute(scheduleForm) })
        .subscribe(response => {
          this.toastrService.clear();
          this.toastrService.success('O agendamento foi atualizado com sucesso!', 'Atualização realizada!');
          this.activeModal.close({
            ...response,
            ...{
              vacancyId: this.eventObj.extendedProps.vacancyId,
              stepId: this.form.value.stepId
            }
          });
        });
    }
  }

  private async openExistingModal() {
    const modal = this.modalService.open(ConfirmModalComponent, { size: 'lg' });
    modal.componentInstance.htmlTitle = 'Já existe um agendamento com este horário.';
    modal.componentInstance.htmlMessage = 'Tem certeza que deseja continuar?';

    return modal.result
}

  private async open48hoursWarningModal() {
      const modal = this.modalService.open(ConfirmModalComponent, { size: 'lg' });
      modal.componentInstance.htmlTitle = 'É recomendado que o agendamento deve ser criado com no mínimo 48 horas de antecedência.';
      modal.componentInstance.htmlMessage = 'Tem certeza que deseja continuar?';

      return modal.result
  }

  verifyIfEventDateIsFeasible(): any {
    const form = this.form.value;
    const eventDate = form.date;
    const eventStartTime = form.start;
    const {year, month, day} = eventDate;
    const { hour, minute } = eventStartTime;
    
    const date = moment(`${year}-${month}-${day} ${hour}:${minute}`, 'YYYY-MM-DD HH:mm');
    const diffTime =  date.diff(moment(), 'hours');

    return diffTime > 48;
  }

  async validateExistingEvent(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let eventExist: boolean = false;
      this.vacanciesService.getVacanciesSchedules(this.vacancyId || this.eventObj.extendedProps.vacancyId)
        .subscribe((response: Array<any>) => {
          // Etapa: Recebe as informações do agendameto.
          const valueDate = this.form.value;

          // Etapa: Define a data inicial do agendamento a ser cadastrado.
          let start = new Date(valueDate.date.year, valueDate.date.month - 1, valueDate.date.day, valueDate.start.hour, valueDate.start.minute);
          // Etapa: Define a data final do agendamento a ser cadastrado.
          let end = new Date(valueDate.date.year, valueDate.date.month - 1, valueDate.date.day, valueDate.end.hour, valueDate.end.minute);

          // Etapa: Agrupando todos os agendamentos em array.
          const schedules = response.flatMap(item => item.schedules);

          schedules.map((schedule: any) => {
            if (schedule.active && new Date(schedule.start).getTime() === start.getTime() || new Date(schedule.end).getTime() === end.getTime()) {
              if(schedule._id !== valueDate._id ) eventExist = true;
            }
          });

          // Etapa: Resolve o retorno com base na regra de negócio.
          resolve(eventExist);
        }, (err) => { });
    });
  }

  dateChange(e): void {
    this.dateObject = {
      year: new Date(e.value).getUTCFullYear(),
      month: new Date(e.value).getUTCMonth() + 1,
      day: new Date(e.value).getUTCDate()
    };
  }

  clearLocation(): void {
    delete this.locationInformationByGoogle;
    delete this.locationImgPreviewUrl;

    if (!this.form.get('location.address.formatted_address').value) {
      this.form.get('location.address.geometry.lat').patchValue(null);
      this.form.get('location.address.geometry.lng').patchValue(null);
    }
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  changeRequiredStatus(event: string): void {
    if (event === 'online') {
      this.removeControl('location');

      this.form.addControl('linkInvite', this.formBuilder.control('', [
        Validators.required,
        Validators.pattern(/https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/)
      ]));
    } else {
      this.form.addControl('location', this.formBuilder.group({
        name: ['', Validators.nullValidator],
        address: this.formBuilder.group({
          formatted_address: [null, Validators.required],
          geometry: this.formBuilder.group({
            lat: [null, Validators.required],
            lng: [null, Validators.required],
          }),
        }),
      }));

      if (this.form.contains('linkInvite')) {
        this.form.removeControl('linkInvite');
      }

      this.form.get('location.address.formatted_address').updateValueAndValidity();
      this.form.get('location.address.geometry.lat').updateValueAndValidity();
      this.form.get('location.address.geometry.lng').updateValueAndValidity();

      setTimeout(() => {
        this.loadMap();
      }, 1000);
    }
  }

  removeControl(params): void {
    Array.prototype.forEach.call(arguments, argument => {
      if (this.form.get(argument)) {
        this.form.removeControl(argument);
      }
    });
  }

  onChanges(): void {
    const manager = this.form.get('manager');
    const managerEmail = this.form.get('managerEmail');
    this.listenChanges = this.form.get('manager').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(val => {
      if (val) {
        manager.setValidators(Validators.required);
        managerEmail.setValidators(Validators.compose([Validators.required, Validators.email]))
      } else {
        manager.setValidators(Validators.nullValidator);
        managerEmail.setValidators(Validators.nullValidator)
      }
      manager.updateValueAndValidity();
      managerEmail.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.listenChanges.unsubscribe();
  }

  public useScheduleBase(scheduleId: String): void {
    const schedule = JSON.parse(JSON.stringify(this.showcaseSchedulingStep.schedules.find((schedule: any) => String(schedule._id) === String(scheduleId))));
    delete schedule._id;

    this.eventObj = JSON.parse(JSON.stringify(schedule));
    this.eventObj.newEvent = true;
    this.eventObj.extendedProps = this.eventObj;

    this.fillForm();
  }

  public getWhatsappScheduleDescription(): string {
    const description = this.form.value.description;
    return description ? `Observações da empresa: ${description.replace(/\s+/g, " ").replace(/\n/g, ' ; ').slice(0, 150).trim()}... (acesse seu email ou app ${this.APP_WHITELABEL_NAME} para a info completa sobre este convite)` : '';
  }

  public getWhatsappScheduleDate(): string {
    const date = new Date(this.form.value.date);
    const start = this.form.value.start;

    if (!date || isNaN(Number(date))) {
      return 'Não informado';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hour = String(start.hour).padStart(2, '0');
    const minute = String(start.minute).padStart(2, '0');
  
    return `${day}/${month}/${year} às ${hour}:${minute}`;
  }

  public getWhatsappScheduleTypeString (): string {
    const interviewFormat = this.form.value.type;
    const type = this.form.value.interviewType;
    const format = [
        "online",
        "remote"
      ].includes(interviewFormat) ? "Online" : "Presencial";

    let typeString = '';
    switch (type) {
        case "dynamicGroup":
            typeString = "Dinâmica em grupo"
            break;
        case "interviewGroup":
            typeString = "Entrevista em grupo"
            break;
        default:
            typeString = "Entrevista individual"
            break;
    }
    return `${typeString} (${format})`;
}
}
