import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ChartsModule } from "ng2-charts";
import { NgSelectModule } from '@ng-select/ng-select';
import { AgmCoreModule } from "@agm/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TextMaskModule } from "angular2-text-mask";
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxPaginationModule } from "ngx-pagination";
import { ClipboardModule } from 'ngx-clipboard';
import { FileUploadModule } from "ng2-file-upload";
import { InlineSVGModule } from "ng-inline-svg";

import { WarningModalComponent } from './warning/warning.component';
import { ExportDataModalComponent } from './export-data-modal/export-data-modal.component';
import { SchoolingModalComponent } from "./schooling-modal/schooling-modal.component";
import { CompanyCertificationsModalComponent } from "./company-certifications-modal/company-certifications-modal.component";
import { ExperienceModalComponent } from "./experience-modal/experience-modal.component";
import { GeolocationModalComponent } from "./geolocation-modal/geolocation-modal.component";
import { GeolocationCityModalComponent } from "./geolocation-city-modal/geolocation-city-modal.component";
import { GenderModalComponent } from "./gender-modal/gender-modal.component";
import { PcdModalComponent } from "./pcd-modal/pcd-modal.component";
import { AgeModalComponent } from "./age-modal/age-modal.component";
import { PersonalityModalComponent } from "./personality-modal/personality-modal.component";
import { HabilitiesModalComponent } from "./habilities-modal/habilities-modal.component";
import { SupplementaryQuestionsModalComponent } from "./supplementary-questions-modal/supplementary-questions-modal.component";
import { PipesModule } from "../../core/pipes/pipes.module";
import { MeaningOfAcronymsPersonalityComponent } from "./personality-modal/meaning-of-acronyms/meaning-of-acronyms-personality.component";
import { FeaturesPersonalityComponent } from "./personality-modal/features/features-personality.component";
import { DescriptionPersonalityComponent } from "./personality-modal/description/description-personality.component";
import { CoreModule } from "../../core/components/core.module";
import { InterventionModalComponent } from "./intervention-modal/intervention-modal.component";
import { EditSupplementaryQuestionsModalComponent } from "./edit-supplementary-questions-modal/edit-supplementary-questions-modal.component";
import { NewRequestModalComponent } from "./new-request-modal/new-request-modal.component";
import { EditProcessesModalComponent } from "./edit-processes-modal/edit-processes-modal.component";
import { EditStatusModalComponent } from "./edit-status-modal/edit-status-modal.component";
import { CloseVacancyModalComponent } from "./close-vacancy-modal/close-vacancy-modal.component";
import { CompaniesModalModule } from "../../companies/modal/companies-modal.module";
import { MaterialCustomModule } from "../../core/modules/material.module";
import { UserService } from '../../core/services/users.service';
import { AddAddressModalComponent } from './add-address-modal/add-address-modal.component';
import { AlertChangesComponent } from "./alert-changes/alert-changes.component";
import { AlertModalComponent } from "./alert-modal/alert-modal.component";
import { AlertContactModalComponent } from "./alert-contact-modal/alert-contact-modal.component";
import { ApproveCandidateModalComponent } from "./approve-candidate-modal/approve-candidate-modal.component";
import { CancelVacancyModalComponent } from "./cancel-vacancy-modal/cancel-vacancy-modal.component";
import { ChangeCandidateSchedulingModalComponent } from "./change-candidate-scheduling-modal/change-candidate-scheduling-modal.component";
import { ChangeStatusCandidatesModalComponent } from "./change-status-candidates-modal/change-status-candidates-modal.component";
import { ChangeStatusModalComponent } from "./change-status-modal/change-status-modal.component";
import { ChangeStatusVacancyModalComponent } from "./change-status-vacancy-modal/change-status-vacancy-modal.component";
import { EditVacancyModalComponent } from "./edit-vacancy-modal/edit-vacancy-modal.component";
import { ModalMessagesNotificationComponent } from "./modal-messages-notification/modal-messages-notification.component";
import { ReturnCandidateForTheProcessComponent } from "./return-candidate-for-the-process-modal/return-candidate-for-the-process-modal.component";
import { ComeBackCandidateToPreviousStepComponent } from "./come-back-candidate-to-previous-step-modal/come-back-candidate-to-previous-step-modal.component";
import { ConfirmCreateStepModal } from "./confirm-create-step-modal/confirm-create-step-modal.component";
import { ConfirmDiscountModalComponent } from './confirm-discount-modal/confirm-discount-modal.component';
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { CreateEventComponent } from "./create-event/create-event.component";
import { DeleteEventComponent } from "./delete-event/delete-event.component";
import { DetailEventComponent } from "./detail-event/detail-event.component";
import { DispenseCandidateModalComponent } from "./dispense-candidate-modal/dispense-candidate-modal.component";
import { GeolocationCompanySearchModalComponent } from './geolocation-company-search-modal/geolocation-company-search-modal.component';
import { InternalEligibilityModalComponent } from './internal-eligibility-modal/internal-eligibility-modal.component';
import { InvitationCandidateModalComponent } from './invitation-candidate-modal/invitation-candidate-modal.component';
import { ListFiltersModalComponent } from "./list-filters-modal/list-filters-modal.component";
import { ListScreeningFiltersModalComponent } from "./list-screening-filters-modal/list-screening-filters-modal.component";
import { LocationsFilterModalComponent } from "./locations-filter-modal/locations-filter-modal.component";
import { NewScheduleModalComponent } from "./new-schedule-modal/new-schedule-modal.component";
import { PreventApplicationsModalComponent } from "./prevent-applications-modal/prevent-applications-modal.component";
import { ReproveCandidateComponent } from './reprove-candidate-modal/reprove-candidate-modal.component';
import { ResolveScheduleModalComponent } from "./resolve-schedule-modal/resolve-schedule-modal.component";
import { SaveFilterModalComponent } from "./save-filter-modal/save-filter-modal.component";
import { SaveScreeningFilterModalComponent } from "./save-screening-filter-modal/save-screening-filter-modal.component";
import { ScheduleModalComponent } from "./schedule-modal/schedule-modal.component";
import { SchedulingCandidatesModalComponent } from './scheduling-candidates-modal/scheduling-candidates-modal.component';
import { SchedulingCandidatesQuantityConfirmationModal } from "./scheduling-candidates-quantity-confirmation-modal/scheduling-candidates-quantity-confirmation-modal.component";
import { ShowMatchmakingDetailModalComponent } from "./show-matchmaking-detail-modal/show-matchmaking-detail-modal.component";
import { SimpleDeleteModalComponent } from "./simple/delete/simple-delete-modal.component";
import { SupplementaryQuestionsBaseModalComponent } from "./supplementary-questions-base-modal/supplementary-questions-base-modal.component";
import { ToggleCandidatesStatusModalComponent } from "./toggle-candidates-status-modal/toggle-candidates-status-modal.component";
import { UnitModalComponent } from "./unit-modal/unit-modal.component";
import { UsersMatchModalComponent } from "./users-match-modal/users-match-modal.component";
import { ViewActionHistoryModalComponent } from './view-action-history-modal/view-action-history-modal.component';
import { ViewHistoryModalComponent } from "./view-history-modal/view-history-modal.component";
import { ViewVacanciesCandidateParticipatingModalComponent } from "./view-vacancies-candidate-participating-modal/view-vacancies-candidate-participating-modal.component";
import { ViewVacanciesCandidateUserReferralModalComponent } from "./view-vacancies-candidate-user-referral-modal/view-vacancies-candidate-user-referral-modal.component";
import { EditOfficeUnitsModalComponent } from './edit-office-units-modal/edit-office-units-modal.component';
import { EditSkillsModalComponent } from './edit-skills-modal/edit-skills-modal.component';
import { EditBenefitsModalComponent } from './edit-benefits-modal/edit-benefits-modal.component';
import { NominateCandidateModalComponent } from './nominate-candidate-modal/nominate-candidate-modal.component';
import { ViewVacanciesCandidateIndicatedByModalComponent } from './view-vacancies-candidate-indicated-by-modal/view-vacancies-candidate-indicated-by-modal.component';
import { ShowcaseCertificationModalComponent } from './showcase-certification-modal/showcase-certification-modal.component';
import { ExclusiveListModalComponent } from './exclusive-list-modal/exclusive-list-modal.component';
import { OkayModalComponent } from './okay-modal/okay-modal.component';
import { QuillModule } from "ngx-quill";
import { EditCompanyModalComponent } from './edit-company-modal/edit-company-modal.component';
import { BulkActionsComponent } from './bulk-actions/bulk-actions.component';
import { BulkActionsLoadingModalComponent } from './bulk-actions-loading-modal/bulk-actions-loading-modal.component';
import { SelectVacancyTemplateModalComponent } from './select-vacancy-template-modal/select-vacancy-template-modal.component';

const modals = [
  AlertChangesComponent,
  AlertModalComponent,
  AddAddressModalComponent,
  ConfirmDiscountModalComponent,
  MeaningOfAcronymsPersonalityComponent,
  FeaturesPersonalityComponent,
  DescriptionPersonalityComponent,
  PersonalityModalComponent,
  SchoolingModalComponent,
  CompanyCertificationsModalComponent,
  ExperienceModalComponent,
  GeolocationModalComponent,
  GeolocationCityModalComponent,
  GeolocationCompanySearchModalComponent,
  GenderModalComponent,
  PcdModalComponent,
  AgeModalComponent,
  HabilitiesModalComponent,
  SupplementaryQuestionsModalComponent,
  InterventionModalComponent,
  EditSupplementaryQuestionsModalComponent,
  CreateEventComponent,
  DetailEventComponent,
  NewRequestModalComponent,
  EditProcessesModalComponent,
  EditStatusModalComponent,
  CloseVacancyModalComponent,
  CancelVacancyModalComponent,
  UsersMatchModalComponent,
  ChangeCandidateSchedulingModalComponent,
  ChangeStatusModalComponent,
  PreventApplicationsModalComponent,
  ChangeStatusVacancyModalComponent,
  EditVacancyModalComponent,
  EditOfficeUnitsModalComponent,
  EditSkillsModalComponent,
  EditBenefitsModalComponent,
  AlertContactModalComponent,
  DeleteEventComponent,
  ApproveCandidateModalComponent,
  ModalMessagesNotificationComponent,
  ReturnCandidateForTheProcessComponent,
  ComeBackCandidateToPreviousStepComponent,
  DispenseCandidateModalComponent,
  ChangeStatusCandidatesModalComponent,
  ListScreeningFiltersModalComponent,
  NewScheduleModalComponent,
  ResolveScheduleModalComponent,
  LocationsFilterModalComponent,
  ToggleCandidatesStatusModalComponent,
  ScheduleModalComponent,
  ViewActionHistoryModalComponent,
  ViewHistoryModalComponent,
  ViewVacanciesCandidateParticipatingModalComponent,
  ViewVacanciesCandidateUserReferralModalComponent,
  SaveScreeningFilterModalComponent,
  SaveFilterModalComponent,
  ListFiltersModalComponent,
  ConfirmModalComponent,
  UnitModalComponent,
  ShowMatchmakingDetailModalComponent,
  SupplementaryQuestionsBaseModalComponent,
  ConfirmCreateStepModal,
  SimpleDeleteModalComponent,
  WarningModalComponent,
  SelectVacancyTemplateModalComponent,
  SchedulingCandidatesQuantityConfirmationModal,
  SchedulingCandidatesModalComponent,
  InternalEligibilityModalComponent,
  NominateCandidateModalComponent,
  ViewVacanciesCandidateIndicatedByModalComponent,
  ReproveCandidateComponent,
  InvitationCandidateModalComponent,
  ExportDataModalComponent,
  ShowcaseCertificationModalComponent,
  ExclusiveListModalComponent,
  OkayModalComponent,
  EditCompanyModalComponent,
  BulkActionsComponent,
  BulkActionsLoadingModalComponent,
];

@NgModule({
  declarations: [
    modals,
    SchedulingCandidatesModalComponent,
    // BannerModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgxPaginationModule,
    AgmCoreModule,
    CoreModule,
    NgbModule,
    TextMaskModule,
    MaterialCustomModule,
    InlineSVGModule.forRoot(),
    FileUploadModule,
    CompaniesModalModule,
    ClipboardModule,
    AngularMultiSelectModule,
    NgSelectModule,
    ChartsModule,
    QuillModule.forRoot()
  ],
  exports: [
    // LocationsFilterModalComponent
    AlertChangesComponent,
    AlertModalComponent, 
    ViewVacanciesCandidateParticipatingModalComponent,
    ExportDataModalComponent,
    WarningModalComponent,
    SelectVacancyTemplateModalComponent,
    NominateCandidateModalComponent
  ],
  entryComponents: modals,
  providers: [
    UserService
  ]
})
export class VacancyModalsModule { }
