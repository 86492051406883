import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { CompaniesService } from '../../../core/services';

@Component({
  selector: 'app-company-certifications-modal',
  templateUrl: './company-certifications-modal.component.html',
  styleUrls: ['./company-certifications-modal.component.scss']
})

export class CompanyCertificationsModalComponent implements OnInit {
  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;
  @Input() companyCertifications: any[];

  public modalForm: FormGroup;
  private allOptions : any[];
  public filteredOptions: any[] = [];
  public selectedOptions: any[] = [];

  constructor(
    protected route: Router,
    protected $hotjar: NgxHotjarService,
    private activeModal: NgbActiveModal,
    private router: Router,
    private formBuilder: FormBuilder,
    private companiesService: CompaniesService
  ) { }

  public ngOnInit() {
    this.selectedOptions = [...this.companyCertifications];

    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.companiesService.getCompaniesCertifications()
      .subscribe((response: any[]) => {
        this.allOptions = response;
        this.filteredOptions = this.filterOptions(null);
      })

    this.modalForm = this.formBuilder.group({
      certification: new FormControl(''),
    });

    this.modalForm.get('certification').valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map(value => this.filterOptions(value))
      )
      .subscribe(filtered => {
        this.filteredOptions = filtered;
      });
  }
  
  public closeModal(): void {
    this.activeModal.close();
  }

  public removeCertification(index: number): void {
    this.selectedOptions.splice(index, 1);
  }

  public filterOptions(value: string): any[] {
    let filterValue: string = "";

    if (typeof value === "string") {
      filterValue = value.toLowerCase();
    }

    const selectedItens: string[] = this.selectedOptions.map(element => String(element._id));

    return this.allOptions.filter(option =>
      !selectedItens.includes(String(option._id)) &&
      (
        option.name.toLowerCase().includes(filterValue) ||
        option.companyName.toLowerCase().includes(filterValue)
      )
    );
  }

  public optionSelected(option: any): void {
    this.selectedOptions.push(option);
    this.inputElement.nativeElement.blur();
  }

  public displayWith(option: any): string {
    return option ? `${option.name} | ${option.companyName}` : '';
  }

  public addCertifications() {
    this.activeModal.close({
      companyCertifications: this.selectedOptions
    });
  }
}
