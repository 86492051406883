import { Injectable } from "@angular/core";
import { Schedule } from "../../core/models";
import * as moment from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "../modal/confirm-modal/confirm-modal.component";

@Injectable()
export class ScheduleService {
  
  constructor(private modalService: NgbModal) {}

  async validateSchedule(schedules: Schedule[], scheduleId: string): Promise<boolean> {
    const schedule = schedules.find(schedule => schedule._id === scheduleId);
    const isEventDatFeasible = this.isEventDateFeasible(schedule);
    if(!isEventDatFeasible) {
      const result = await this.openModalWarning('Atenção!', 'A data do evento está muito próxima. Deseja continuar mesmo assim?');
     return result; 
    }
    return true;
  }

  isEventDateFeasible(schedule: Schedule): boolean {
    const eventStartTime = schedule.start;
    const diffTime = moment(eventStartTime).diff(moment(), "hours");
    return diffTime > 48;
  }

  async openModalWarning(title: string, message: string) {
    const modal = this.modalService.open(ConfirmModalComponent, { size: 'lg' });
    modal.componentInstance.htmlTitle = title;
    modal.componentInstance.htmlMessage = message;
    return modal.result
  }
}
